@tailwind base;
@tailwind components;
@tailwind utilities;

/* Start Container */

.container {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

/* End Container */

.menu {
  display: none;
  flex-direction: column;
  position: relative;
  height: 80px;
  width: 35px;
  border: none;
  outline: none;
  background-color: transparent;
  /* margin-right: 30px; */
  cursor: pointer;
  justify-content: center;
  gap: 2px;
}

@media (max-width: 768px) {
  .menu {
      display: flex !important;
      align-items: center;
      justify-content: center;
  }
}

.menu span {
  display: block;
  width: 27px;
  height: 2px;
  margin: 3px auto;
  background-color: black;
  transition: all 0.3s ease-in-out;
}

.menu.active span:nth-child(1) {
  transform: rotate(45deg) translate(-1px, 15px);
  background-color: red;
}

.menu.active span:nth-child(2) {
  opacity: 0;
}

.menu.active span:nth-child(3) {
  transform: rotate(-45deg) translate(-1px, -15px);
  background-color: red;
}


html {
  direction: ltr;
}

html[lang="ar"] {
  direction: rtl;
}

[lang="ar"] .image-landing-page {
  left: 0 !important;
  right: auto;
}

[lang="ar"] .button-landing {
  background-color: white !important;
  color: #ff723a !important;
}

[lang="ar"] .home-pages-title {
  color: white !important;
}


.landing {
  background-image: url(./Assest/images/01.jpg);
  height: 35vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.js-scroll {
  opacity: 0;
}

.js-scroll.scrolled {
  opacity: 1;
}

.scrolled.fade-left {
  animation: fadeleft 800ms ease forwards;
}

.scrolled.fade-right {
  animation: faderight 800ms ease forwards;
}

.scrolled.fade-top {
  animation: fadetop 800ms ease forwards;
}

.scrolled.fade-btm {
  animation: fadebtm 800ms ease forwards;
}

@keyframes fadeleft {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes faderight {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadetop {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadebtm {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


.btmAni30002 {
  opacity: 0;
  transform: translateY(200px);
  animation: btmAni 800ms cubic-bezier(0.215, 0.61, 0.355, 1) 1.6s forwards;
}

.btmAni2500 {
  opacity: 0;
  transform: translateY(200px);
  animation: btmAni 800ms cubic-bezier(0.215, 0.61, 0.355, 1) 1.5s forwards;
}

.btmAni3000 {
  opacity: 0;
  transform: translateY(200px);
  animation: btmAni 800ms cubic-bezier(0.215, 0.61, 0.355, 1) 1.4s forwards;
}

.btmAni20002 {
  opacity: 0;
  transform: translateY(200px);
  animation: btmAni 800ms cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s forwards;
}

.btmAni25002 {
  opacity: 0;
  transform: translateY(200px);
  animation: btmAni 800ms cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s forwards;
}

.btmAni2000 {
  opacity: 0;
  transform: translateY(200px);
  animation: btmAni 800ms cubic-bezier(0.215, 0.61, 0.355, 1) 1.1s forwards;
}


@keyframes btmAni {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  height: 80%;
  overflow: auto;
}

.close {
  cursor: pointer;
  font-size: 20px;
  background-color: red;
  color: white;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.home{
  background-image: url(./Assest/images/home.png);
  height: 88vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.main-title{
  margin: 40px auto;
  width: fit-content;
  font-size: 35px;
  text-transform: uppercase;
  font-weight: bold;
  color: #ff723a;
  border-bottom: 8px solid #ff723a;
}

.major{
  background-image: url(./Assest/images/back-major.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.consultations{
  background-image: url(./Assest/images/consultations.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 69%;
  height: 75vh;
}

.goTop {
  display: none;
  position: fixed;
  bottom: 5%;
  right: 10px;
  z-index: 5;
}

@media (max-width: 768px) {
  .goTop {
    right: 15px;
  }
}

.goTop span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: #ff723a;
  box-shadow: 0px 0px 10px var(--main-blue-color);
  cursor: pointer;
  transition: 0.2s;
}

.goTop span:hover {
  background-position: right center;
}

.goTop span:hover i {
  color: #fff;
}

.goTop span i {
  color: #fff;
  font-size: 1.5em;
}


/* Responsive */

@media (max-width: 768px) {
  .d-f{
    display: flex;
  }
}

@media (max-width: 768px) {
  .respon-100{
    width: 100%;
  }
}

@media (max-width: 768px) {
  .d-none{
    display: none ;
  }
}

@media (max-width: 768px) {
  .f-col{
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .f-center{
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .text-center{
    text-align: center;
  }
}

@media (max-width: 768px) {
  .g-20{
    gap: 20px;
  }
}
